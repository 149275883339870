function _localhost () {
  return window.location.hostname
}
function _returnData(envs) {
  return this.isProduction ? envs.prod : envs.uat
}

class Settings {
  constructor() {
    this.isSit = this.isSits()
    this.isUAT = this.isUATs()
    this.isDev = this.isDevs()
    this.isProduction = this.isProductions()
  }
  isProductions() {
    const host = _localhost.call(this)
    return host === 'cms-usa.platanus.com'
  }
  isUATs() {
    const host = _localhost.call(this)
    return host === 'cms-uat-usa.platanus.com'
  }
  isSits() {
    const host = _localhost.call(this)
    return host === 'cms-sit-usa.platanus.com'
  }
  isDevs() {
    const host = _localhost.call(this)
    return host === 'cms-dev.platanus.com' || host === 'localhost'
  }

  returnUrl(envs) {
    if (this.isDevs) {
      return envs.dev
    } else if (this.isSit) {
      return envs.sit
    } else if (this.isUAT) {
      return envs.uat
    } else {
      return envs.prod
    }
  }

  CAPITAL_BASE_URL() {
    const envs = {
      uat: 'https://portal-uat-usa.platanus.com/#/manage/contractNew',
      prod: 'https://portal-usa.platanus.com/#/manage/contractNew',
      sit: 'https://portal-sit-usa.platanus.com/#/manage/contractNew',
      dev: 'https://portal-dev.platanus.com/#/manage/contractNew'
    }
    return this.returnUrl(envs)
  }
  /**
   * capital 投管地址
   * @returns
   */
  CAPITAL_URL() {
    const envs = {
      uat: 'https://portal-uat-usa.platanus.com/#/projectApproval',
      prod: 'https://portal-usa.platanus.com/#/projectApproval',
      sit: 'https://portal-sit-usa.platanus.com/#/projectApproval',
      dev: 'https://portal-dev.platanus.com/#/projectApproval'
    }
    return this.returnUrl(envs)
  }
}

module.exports = new Settings()
